<template>
  <relato :idEmpresa="id" :nomeDoRelatante="nome"/>
</template>

<script>
import relato from '@/components/my-components/ralatos/form/fazerRelato.vue';

export default {
  components: { relato },
  data() {
    return {
      id: null,
      nome: null,
    };
  },
  created() {
    this.id = Number(localStorage.empresaSelecionada);
    this.nome = JSON.parse(localStorage.user).funcionario.nome;
  },
};
</script>

<style>
.form-group.flex.col-sm-6 {
  margin: 0px !important;
}
.form-group.col-sm-6.style-no-left.pr-4 {
  margin-top: 0.4rem;
}
</style>
